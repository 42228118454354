.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.custom-alert-container {
  width: 320px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 20000;
}

.custom-alert-header {
  /*padding: 16px;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*border-bottom: 1px solid #e8e8e8;*/
}

.custom-alert-header h2 {
  font-size: 18px;
  margin: 0;
}

.custom-alert-close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.custom-alert-close span {
  font-size: 24px;
  line-height: 1;
}

.custom-alert-content {
  padding: 16px;
  text-align: center;
  color: rgb(164,164,164);
}

.custom-alert-footer {
  padding: 16px;
  text-align: center;
  border-top: 1px solid #e8e8e8;
}

.custom-alert-button {
  color: rgb(94, 107, 144);
  cursor: pointer;
  border: none;
  border-radius: 4px;
  /* padding: 8px 16px; */
  width: 100%;
  font-size: 20px;
  height: 33px;
  font-weight: bold;
  background: white;
}

